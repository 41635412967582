import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-form-on-change"
export default class extends Controller {
  static targets = ["form", "fileInput", "uploadButton", "refreshButton", "uploadBaseIcon", "uploadInProgressIcon"]

  onFileInputChange(e) {
    this.formTarget.requestSubmit();
    this.fileInputTarget.disabled = true

    this.uploadButtonTarget.classList.add('cursor-not-allowed')

    setTimeout(() => {
      this.refreshButtonTarget.classList.add('flex')
      this.refreshButtonTarget.classList.remove('hidden')

      this.uploadButtonTarget.classList.remove('flex')
      this.uploadButtonTarget.classList.add('hidden')

    }, 4000);
  }
}
